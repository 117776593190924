const category = ["Water", "Chips", "Hummus"];
const products_list = [2, 3, 4, 5, 6, 7, 9, 10, 11, 12];
const products = {
    1: {
        name: "Baba Ghanouj",
        category: "Baba Ghanouj",
        image: require("../assets/img/products/baba_ghanouj.jpg").default,
        content:"",
        id: 1,
    },
    2: {
        name: "Hummus With Avacado",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_avacado.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 2,
    },
    3: {
        name: "Hummus With Chili Pepper",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_chili_pepper.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 3,
    },
    4: {
        name: "Hummus With Garlic",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_garlic.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 4,
    },
    5: {
        name: "Hummus With Greek Olive",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_greek_olive.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 5,
    },
    6: {
        name: "Hummus With Pine Nuts",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_pine_nuts.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 6,
    },
    7: {
        name: "Hummus With Roasted Red Pepper",
        category: "Hummus",
        image: require("../assets/img/products/hummus_with_roasted_red_pepper.jpg").default,
        content: "This is absolutely the one for you and your family especially if devoured with chips or sliced vegetables. The sesame paste added can make a GOLAN Hummus taste more authentic.",
        id: 7,
    },
    8: {
        name: "Pita Chips 1",
        category: "Chips",
        image: require("../assets/img/products/pita_chips_1.jpg").default,
        content:"",
        id: 8,
    },
    9: {
        name: "Water 300ml",
        category: "Water",
        image: require("../assets/img/products/water_300ml.jpg").default,
        content:"",
        id: 9,
    },
    10: {
        name: "Water 600ml",
        category: "Water",
        image: require("../assets/img/products/water_600ml.jpg").default,
        content:"",
        id: 10,
    },
    11: {
        name: "Water 1500ml",
        category: "Water",
        image: require("../assets/img/products/water_1500ml.jpg").default,
        content:"",
        id: 11,
    },
    12: {
        name: "Pita Chips",
        category: "Chips",
        image: require("../assets/img/products/pita_chips_2.jpg").default,
        content:"",
        id: 12,
    },
};

const options = {
    category,
    products_list,
    products,
};

export default options