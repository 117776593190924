import Template from "./template/Template";
import ProductDetail from "./products/detail/ProductDetail";
import Lost from "./404"
import { Switch, Route, HashRouter } from "react-router-dom";
import Landing from "./landing/Landing";
// import ProductList from "./products/ProductList";

function App() {
  return (
    <Template>
      <HashRouter>
        <Switch>
          <Route path="/products/:slug">
            <ProductDetail />
          </Route>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route  path="*">
            <Lost />
          </Route>
        </Switch>
        </HashRouter>
    </Template>
  );
}

export default App;
