import { Link } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header() {
  const [openedDrawer, setOpenedDrawer] = useState(false);

  function toggleDrawer() {
    setOpenedDrawer(!openedDrawer);
  }

  function changeNav(event) {
    if (openedDrawer) {
      setOpenedDrawer(false);
    }
  }

  const logo = require("../assets/img/logo.jpg").default;
  return (
    <header>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white border-bottom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" onClick={changeNav}>
            <img src={logo} alt="GoLan" className="logo" />
          </Link>

          <div
            className={
              "navbar-collapse offcanvas-collapse " +
              (openedDrawer ? "open" : "")
            }
          >
            <ul className="navbar-nav me-auto mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  Our Story
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  Healthy tips
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  Blog
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  FAQ
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link" replace onClick={changeNav}>
                  Contact us
                </Link>
              </li>
            </ul>
            <span>
              <form className="search-container" action="">
                <input
                  type="text"
                  placeholder="Search.."
                  name="search"
                  className="search-input"
                />
                <button type="submit" className="submit-btn">
                  <FontAwesomeIcon icon={["fas", "search"]} />
                </button>
              </form>
            </span>
            {/* <button type="button" className="btn btn-outline-dark me-3 d-none d-lg-inline">
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
              <span className="ms-3 badge rounded-pill bg-dark">0</span>
            </button>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  href="!#"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={["fas", "user-alt"]} />
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="userDropdown"
                >
                  <li>
                    <Link to="/" className="dropdown-item" onClick={changeNav}>
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link to="/" className="dropdown-item" onClick={changeNav}>
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </li>
            </ul> */}
          </div>

          <div className="d-inline-block d-lg-none">
            {/* <button type="button" className="btn btn-outline-dark">
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
              <span className="ms-3 badge rounded-pill bg-dark">0</span>
            </button> */}
            <button
              className="navbar-toggler p-0 border-0 ms-3"
              type="button"
              onClick={toggleDrawer}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
