function BannerIncidator(props) {
  return (
    <button
      type="button"
      data-bs-target="#bannerIndicators"
      data-bs-slide-to={props.index}
      className={props.active ? "active" : ""}
      aria-current={props.active}
    />
  );
}

function BannerImage(props) {
  return (
    <div
      className={"carousel-item " + (props.active ? "active" : "")}
      data-bs-interval="5000"
    >
      <div
        className="ratio"
        style={{ "--bs-aspect-ratio": "50%", maxHeight: "93vh" }}
      >
        <img
          className="d-block w-100 h-100 bg-dark cover"
          alt=""
          src={props.image}
        />
      </div>
      <div className="carousel-caption d-none d-lg-block">
        <h5>{props.header}</h5>
        <p>{props.content}</p>
      </div>
    </div>
  );
}

function Banner() {
  const water = require("../assets/img/banners/water.jpg").default;
  const kids = require("../assets/img/banners/kids.jpg").default;
  const milk = require("../assets/img/banners/milk.jpg").default;
  const juice = require("../assets/img/banners/juice.jpg").default;

  return (
    <div
      id="bannerIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
      style={{ marginTop: "56px" }}
    >
      <div className="carousel-indicators">
        <BannerIncidator index="0" active={true} />
        <BannerIncidator index="1" />
        <BannerIncidator index="2" />
        <BannerIncidator index="3" />
      </div>
      <div className="carousel-inner">
        <BannerImage image={water} active={true} header="Water" content="Healthy Life" />
        <BannerImage image={kids} header="Kids" content="Happy Family"/>
        <BannerImage image={milk} header="Milk" content="Powerful Mind"/>
        <BannerImage image={juice} header="Juice" content="More Vitamin"/>
      </div>
    </div>
  );
}

export default Banner;
