// import Image from "../nillkin-case.webp";
import { Link } from "react-router-dom";
import options from "../util/config"


function renderClickableTemplate(product) {
  return (
    <Link to={`/products/${product.id}`} className="auto-text-decoration">
      {renderTemplate(product)}
    </Link>
  )
}

function renderTemplate(product) {
  return (
    <>
      <img
        className="card-img-top"
        height="240"
        alt=""
        src={product.image}
      />

      <div className="card-body">
        <h5 className="card-title text-center">{product.name}</h5>
        <p className="card-text text-muted text-truncate">{product.content}</p>
      </div>
    </>
  )
}

function FeatureProduct({ product_id }) {
  const product = options.products[product_id];

  return (
    <div className="col">
      <div className={`card shadow-sm ${product.content? "cursor-pointer hover-shadow":"cursor-not-allowed"}`}>
        {product.content ? renderClickableTemplate(product) : renderTemplate(product)}
      </div>
    </div>
  );
}

export default FeatureProduct;
